<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card elevation="0">
          <v-card-title class="pa-0 ma-0">
            <v-row>
              <v-col class="pt-2 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
                    <b-input-group width="100%">
                      <b-form-input :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" v-model="search"
                                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                    :placeholder="$t('datatables.lang_search')"
                                    @focus="showTouchKeyboard"/>
                    </b-input-group>
                  </div>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <v-btn class="white--text"
                           color="info"
                           elevation="1"
                           fab small
                           @click="$router.push({name:'customer.createCustomer.project',params:{id:$props.customerId}})">
                      <v-icon>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                    <b-dropdown no-caret right toggle-class="btn-icon btn-icon-only" variant="link">
                                            <span slot="button-content"><i
                                                class="pe-7s-menu btn-icon-wrapper"></i></span>
                      <div>
                        <h6 class="dropdown-header" tabindex="-1">
                          {{ this.$t('generic.lang_Options') }}</h6>
                        <button :disabled="selectedRows.length<1" class="dropdown-item" tabindex="0"
                                type="button" @click="deleteEntries()"
                        >
                          <v-icon class="dropdown-icon" color="red">
                            mdi-delete
                          </v-icon>
                          <span>{{ $t('generic.lang_delete') }}</span></button>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
              v-model="selectedRows"
              :footer-props="footerProps"
              :headers="filteredDatatableHeaders"
              :items="filteredDatatableEntries"
              :loading="loading"
              :options.sync="pagination"
              :server-items-length="total"
              :show-select="true"
              footer-props.items-per-page-text="Einträge pro Seite"
          >
            <template v-slot:item.projectColor="{ item }">
              <v-chip :color="item.projectColor" dark></v-chip>
            </template>

            <template v-slot:item.certificate="{ item }">
              <v-btn icon>
                <font-awesome-icon :icon="['fal','file-certificate']" class="dropdown-icon warning--text"
                                   style="font-size: 25px"
                                   @click="download_certificate(item)"/>
              </v-btn>
            </template>


            <template v-slot:item.status="{ item }">
              <div>
                <v-switch v-model="item.status" inset @change="changeStatus(item)"/>
              </div>
            </template>

            <template v-slot:item.crud="{ item }">
              <v-row align="center" justify="center">
                <v-btn class="mx-auto" color="primary" icon text @click="editEntry(item)">
                  <v-icon>edit</v-icon>
                </v-btn>

                <v-btn class="mx-auto" color="error" icon text @click="deleteEntry(item)">
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"/>
        </div>
      </v-card-text>

      <!-- PROJECT PDF DIALOG -->
      <v-dialog persistent v-model="displayProjectDialog" width="650">
        <v-card>
          <v-card-text style="height: 600px;">
            <iframe :src="iframePDFContent" height="550" v-if="iframePDFContent.length > 0" width="600"/>
          </v-card-text>

          <v-card-actions style="background-color: white;">
            <v-spacer></v-spacer>
            <v-btn @click="displayProjectDialog = false" color="blue darken-1" dark text>{{ $t('generic.lang_close') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-card>
  </v-container>
</template>

<script>
import FileSaver from 'file-saver';
import Datatable from "../datatable/Datatable";
import {ENDPOINTS} from "../../config";
import {Events} from "../../plugins/events";
import mixin from "../../mixins/KeyboardMixIns"
import Template from "../settings/customerdisplay/Template";
import moment from 'moment'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {library} from '@fortawesome/fontawesome-svg-core'

import {faFileCertificate} from '@fortawesome/pro-light-svg-icons';

library.add(faFileCertificate);

export default {
  name: "CustomerProjectsComponent",
  components: {Template, Datatable, 'font-awesome-icon': FontAwesomeIcon},
  props: {
    customerId: {
      required: true,
    },
    customerPreName: {
      required: true,
    },
    customerLastName: {
      required: true,
    }
  },
  mixins: [mixin],
  data() {
    return {
      /*
      * array('uuid', 'projectName', 'projectNumber', 'startDateTimeStamp', 'projectColor', 'projectStatus', 'projectActive');
      * */
      datatableHeaders: [
        {
          text: 'UUID',
          align: 'left',
          hide: true,
          value: 'id'
        },
        {
          text: this.$t('customers.lang_projectNumber'),
          align: 'left',
          value: 'projectNumber',
        },
        {
          text: this.$t('customers.lang_projectName'),
          align: 'left',
          value: 'projectName'
        },
        {
          text: this.$t('customers.lang_projectPrice'),
          align: 'left',
          value: 'projectPrice',
        },
        {
          text: this.$t('customers.lang_customerProjectDate'),
          align: 'left',
          value: 'startDate'
        },
        {
          text: this.$t('erp.lang_warecreate_color'),
          align: 'left',
          value: 'projectColor'
        },
        {
          text: this.$t('generic.lang_certificate'),//status
          align: 'center',
          value: 'certificate',
          sortable: false,
        },
        {
          text: this.$t('generic.lang_status'),//status
          align: 'left',
          value: 'status',
        },
        {
          text: 'Active',//active
          align: 'left',
          value: 'active',
          hide: true,
        },
        {
          text: '',
          value: 'crud',
          sortable: false,
          width: '230',
          align: 'end',
        }
      ],
      loading: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: '',
      total: 0,
      pagination: {},
      footerProps: {'items-per-page-options': [15, 30, 50, 100]},
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      displayProjectDialog: false,
      iframePDFContent: "",
    }
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });
      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];

            /*  if (header.value === "certificate") {
                header.value = "status";
              }*/

            if (header.value === "status") {
              row[header.value] = data[index - 1];
              if (row[header.value] == "2") {
                row[header.value] = true;
              } else {
                row[header.value] = false;
              }
            }
            if (header.value === "startDate") {
              row[header.value] = moment(data[index], "hh:mm:ss DD loc.MM.YY").format('YYYY-MM-DD')
            }
          }

        });

        dataToRender.push(row);
      });

      return dataToRender;
    }
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    }
  },
  methods: {
    download_certificate(item) {
      this.axios.post(ENDPOINTS.CUSTOMERS.PROJECTS.PROJECTREPORT, {
            projectUUID: item.id
          },
          {
            responseType: 'arraybuffer',
          }).then((res) => {
        //GENERATE PDF CONTENT FOR iFRAME
        const blobContent = new Blob([res.data], {type: "application/pdf"});
        this.iframePDFContent = URL.createObjectURL(blobContent);
        this.displayProjectDialog = true;

        //DOWNLOAD INVOICE AS PDF
        FileSaver.saveAs(new Blob([res.data], {
          type: "application/pdf"
        }), this.$props.customerId +"_"+ item.projectNumber + "_"+this.$props.customerLastName+".pdf");
      }).catch((err) => {
        ////console.log(err);
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
      })
    },
    editEntry(entry) {
      this.$router.push({name: "customer.editCustomer.project", params: {id: entry.id}})
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.loading = true;
      this.$swal({
        title: this.$t('generic.lang_deleteProject'),
        text: this.$t('generic.lang_areYouSureYouWantToDeleteTheProject'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.axios.post('delete/customer/customerProject/', {
            customerProjectUUID: idsToDelete
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_projectDeleted'),
                color: "success"
              });
              self.getDataFromApi();
              self.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
            this.loading = false;
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    deleteEntries() {
      let ids = []
      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });
      this.deleteData(ids)
    },
    switchStatus(id, status) {

      let self = this;
      this.loading = true;
      this.axios.post('update/customer/customerProjectToggleState/', {
        projectUUID: id,
        projectStatus: status,
      }).then((res) => {
        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success"
          });
          this.getDataFromApi();
          this.resetSelectedRows();
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
        this.loading = false;
      })
    },
    changeStatus(entry) {

    //  ////console.log(entry.status)
      if (entry.status === true) {
        this.switchStatus(entry.id, 2)
      } else {
        this.switchStatus(entry.id, 1)
      }
    },
    toggleStatus(entry) {
      this.switchStatus(entry.uuid, 1)
    },
    enabled(id) {
      this.switchStatus(id, 1)
    },
    disable(id) {
      this.switchStatus(id, 0)
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(header => header.value === this.pagination.sortBy[0]);

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? 'desc' : 'asc';
      }
      params['customerID'] = parseInt(this.$props.customerId);


      this.axios.get(ENDPOINTS.DATATABLES.CUSTOMERS.CUSTOMERPROJECTS, {
        params: params
      }).then((res) => {
        this.total = parseInt(res.data.iTotalDisplayRecords);
        //let data=res.data.aaData;
        this.dataToShow = res.data.aaData;
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

        this.dataToShow = [];
      });
    }
  },
  mounted() {
    this.getDataFromApi();
  }
}
</script>

<style scoped>

</style>
