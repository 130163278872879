<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col class="pt-0 pb-0" :key="allergen.id" v-for="allergen in  this.allergens" cols="12" sm="6" md="4">
            <v-checkbox v-model="selected" color="primary" :value="allergen.id" :label="allergen.name"/>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12">
        <v-btn :loading="loader" :disabled="loader" @click="save" elevation="0" color="primary" block class="mx-auto">
          {{ this.$t('generic.lang_save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

export default {
  name: "CustomerAllergens",
  data: () => {
    return {
      allergens: [],
      loader: false,
      selected: []
    }
  },
  async mounted() {
    await this.loadData()
  },
  methods: {
    async loadData() {

      this.loader = true;
      await this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.GET, {
        customerId: this.$route.params.id
      }).then((res) => {
        if (res.data.success) {
          this.selected = res.data.allergens || []
        }
      })

      await this.axios.post(ENDPOINTS.ERP.ALLERGENE.GETALL).then((res) => {
        /*this.allergens = res.data;*/
        if (res.data.STATUS === 'SUCCESS') {
          this.allergens = res.data.allergens || []
        }
      }).finally(() => this.loader = false)
    },
    save() {
      this.loader = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEE.ALLERGENS.UPDATE, {
        customerId: this.$route.params.id,
        customerAllergens: this.selected
      }).then((res) => {
        if (res.data.success) {
          Events.$emit("showSnackbar", {
            message: this.$t('customers.lang_customerUpdated'),
            color: "success"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => {
        this.loader = false;
      })
    }
  }
}
</script>

<style>
.v-input--selection-controls .v-input__slot > .v-label, .v-input--selection-controls .v-radio > .v-label {
  top: 5px !important;
}
</style>
