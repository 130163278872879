<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card elevation="0">
          <v-card-title class="pa-0 ma-0">
            <v-row>
              <v-col class="pt-2 ma-0" cols="12">
                <div class="card-header-tab card-header">
                  <div
                    class="
                      card-header-title
                      font-size-lg
                      text-capitalize
                      font-weight-normal
                    "
                  >
                    <b-input-group width="100%">
                      <b-form-input
                        :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']"
                        v-model="search"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        :placeholder="$t('datatables.lang_search')"
                        @focus="showTouchKeyboard"
                      />
                    </b-input-group>
                  </div>
                  <div class="btn-actions-pane-right actions-icon-btn">
                    <v-btn
                      class="white--text"
                      color="info"
                      elevation="1"
                      fab
                      small
                      @click="createEntry()"
                    >
                      <v-icon> mdi-plus </v-icon>
                    </v-btn>
                    <b-dropdown
                      no-caret
                      right
                      toggle-class="btn-icon btn-icon-only"
                      variant="link"
                    >
                      <span slot="button-content"
                        ><i class="pe-7s-menu btn-icon-wrapper"></i
                      ></span>
                      <div>
                        <h6 class="dropdown-header" tabindex="-1">
                          {{ this.$t("generic.lang_Options") }}
                        </h6>
                        <button
                          :disabled="selectedRows.length < 1"
                          class="dropdown-item"
                          tabindex="0"
                          type="button"
                          @click="deleteEntries()"
                        >
                          <v-icon class="dropdown-icon" color="red">
                            mdi-delete
                          </v-icon>
                          <span>{{ $t("generic.lang_delete") }}</span>
                        </button>
                      </div>
                    </b-dropdown>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-title>

          <v-data-table
            v-model="selectedRows"
            :footer-props="footerProps"
            :headers="filteredDatatableHeaders"
            :items="filteredDatatableEntries"
            :loading="loading"
            :options.sync="pagination"
            :server-items-length="total"
            :show-select="true"
            footer-props.items-per-page-text="Einträge pro Seite"
          >
            <template v-slot:item.crud="{ item }">
              <v-row align="center" justify="center">
                <v-btn
                  class="mx-auto"
                  color="primary"
                  icon
                  text
                  @click="editEntry(item)"
                >
                  <v-icon>edit</v-icon>
                </v-btn>

                <v-btn
                  class="mx-auto"
                  color="error"
                  icon
                  text
                  @click="deleteEntry(item)"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-table>
        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard
            v-if="touchKeyboard.visible"
            id="onScreenKeyboard"
            :accept="hideTouchKeyboard"
            :cancel="hideTouchKeyboard"
            :defaultKeySet="touchKeyboard.keySet"
            :input="touchKeyboard.input"
            :layout="touchKeyboard.layout"
            :options="touchKeyboard.options"
            class="internalWidthExpanded"
          />
        </div>
      </v-card-text>

      <!-- ADD NEW CONTACT -->
      <CreateCustomerContactComponent ref="create"
        :dialogCreate="dialogCreate"
        @closeDialogCreate="closeDialogCreate"
      ></CreateCustomerContactComponent>

      <!-- EDIT CONTACT -->
      <EditCustomerContactComponent ref="edit"
        :dialogEdit="dialogEdit"
        @closeDialogEdit="closeDialogEdit"
      ></EditCustomerContactComponent>
    </v-card>
  </v-container>
</template>

<script>
import FileSaver from "file-saver";
import Datatable from "../../datatable/Datatable";
import { ENDPOINTS } from "../../../config";
import { Events } from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns";
import Template from "../../settings/customerdisplay/Template";
import moment from "moment";
import CreateCustomerContactComponent from "./CreateCustomerContactComponent.vue";
import EditCustomerContactComponent from "./EditCustomerContactComponent.vue";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";

import { faFileCertificate } from "@fortawesome/pro-light-svg-icons";

library.add(faFileCertificate);

export default {
  name: "CustomerContactsComponent",
  components: {
    Template,
    Datatable,
    CreateCustomerContactComponent,
    EditCustomerContactComponent,
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    customerId: {
      required: true,
    },
  },
  mixins: [mixin],
  data() {
    return {
      /*
       * array('id', 'customerCardID', 'company', 'first_name', 'last_name', 'street', 'zip', 'city', 'birthday', 'email', 'ascContactType');
       * */
      datatableHeaders: [
        {
          text: "ID", //ID
          align: "left",
          value: "id",
          width: 60,
          hide: false,
        },
        {
          text: "CustomerCardID", //customerCardID
          align: "left",
          value: "customerCardID",
          width: 80,
          hide: true,
        },
        {
          text: this.$t("generic.lang_company"), //company
          align: "left",
          value: "company",
        },
        {
          text: this.$t("customers.lang_cust_prename"), //first_name
          align: "left",
          value: "first_name",
        },
        {
          text: this.$t("customers.lang_cust_name"), //last_name
          align: "left",
          value: "last_name",
        },
        {
          text: this.$t("generic.lang_street"), //street
          align: "left",
          value: "street",
        },
        {
          text: this.$t("customers.lang_cust_zip"), //status
          align: "left",
          value: "zip",
        },
        {
          text: this.$t("settings.lang_city"), //city
          align: "left",
          value: "city",
        },
        {
          text: this.$t("customers.lang_cust_birth"), //birthday
          align: "left",
          value: "birthday",
          hide: true,
        },
        {
          text: this.$t("generic.lang_emailAdresse"), //email
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: this.$t("customers.lang_contact_type"), //ContactType
          align: "left",
          value: "ascContactType",
        },
        {
          text: "",
          value: "crud",
          sortable: false,
          width: "230",
          align: "end",
        },
      ],
      dialogEdit: false,
      dialogCreate: false,
      loading: false,
      selectedRows: [],
      awaitingSearch: null,
      dataToShow: [],
      search: "",
      total: 0,
      pagination: {},
      footerProps: { "items-per-page-options": [15, 30, 50, 100] },
      rowsPerPageItems: [10, 20, 50, 100],
      id: null,
      iframePDFContent: "",
    };
  },
  computed: {
    filteredDatatableHeaders: function () {
      let headers = [];

      this.datatableHeaders.forEach((header) => {
        if (header.hasOwnProperty("hide")) {
          if (header.hide) {
            return;
          }
        }
        headers.push(header);
      });
      return headers;
    },
    filteredDatatableEntries: function () {
      let dataToRender = [];

      this.dataToShow.forEach((data) => {
        let row = {};

        this.datatableHeaders.forEach((header, index) => {
          if (!(header.hidden && header.hidden == true)) {
            row[header.value] = data[index];


            if (header.value === "ascContactType") {
              row[header.value] = data[index];

              if (row[header.value] == "1") {
                row[header.value] = this.$t('generic.lang_contact');
              } else if (row[header.value] == "2") {
                row[header.value] = this.$t('accounting.lang_receipt_adress');
              } else if (row[header.value] == "3") {
                row[header.value] = this.$t('settings.lang_deliveryAddress');
              } else {
                row[header.value] = "";
              }
            }
          }
        });

        dataToRender.push(row);
      });

      return dataToRender;
    },
  },

  watch: {
    search: function () {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        if (this.pagination.page !== 1) {
          this.pagination.page = 1;
        } else {
          this.getDataFromApi();
        }
      }, 500);
    },
    pagination: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    selectedRows: function () {
      this.$emit("changeSelectedRows", this.selectedRows);
    },
  },
  methods: {
    createEntry() {
      this.dialogCreate = true;
      this.$refs.create.getData();
    },
    closeDialogCreate() {
      this.dialogCreate = false;
      this.getDataFromApi();
      this.resetSelectedRows();
    },
    editEntry(entry) {
      this.dialogEdit = true;
      this.$refs.edit.getData(entry);
    },
    closeDialogEdit() {
      this.dialogEdit = false;
      this.getDataFromApi();
      this.resetSelectedRows();
    },
    deleteEntry(entry) {
      this.id = entry.id;
      this.deleteData();
    },
    deleteEntries() {
      let ids = [];
      this.selectedRows.forEach((row) => {
        ids.push(row.id);
      });
      this.deleteData(ids);
    },
    deleteData(idsToDelete = []) {
      let self = this;

      this.$swal({
        title: this.$t('customers.lang_deleteContact'),
        text: this.$t('customers.lang_deleteContactConfirm'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.id);
          }
          this.loading = true;

          this.axios
            .post(ENDPOINTS.CUSTOMERS.CUSTOMER.DELETE, {
              customerID: idsToDelete,
            })
            .then((res) => {
              if (res.data.status === "SUCCESS") {
                Events.$emit("showSnackbar", {
                  message: this.$t('generic.lang_projectDeleted'),
                  color: "success",
                });
                self.getDataFromApi();
                self.resetSelectedRows();
              } else {
                Events.$emit("showSnackbar", {
                  message: this.$t("generic.lang_errorOccurred"),
                  color: "error",
                });
              }
              this.loading = false;
            })
            .finally(() => {
              this.loading = false;
            });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    resetSelectedRows() {
      this.selectedRows = [];
    },
    getDataFromApi() {
      this.loading = true;
      this.selectedRows = [];

      let params = {
        sEcho: 1,
        iDisplayStart: this.pagination.itemsPerPage * (this.pagination.page - 1),
        iDisplayLength: this.pagination.itemsPerPage,
      };

      //SORTING / PAGINATION / SEARCHING
      if (this.search) {
        params.sSearch = this.search;
      }

      if (this.pagination.sortBy) {
        //FIND COL. INDEX
        let index = this.datatableHeaders.findIndex(
          (header) => header.value === this.pagination.sortBy[0]
        );

        if (index < 0) {
          index = 0;
        }

        params["iSortingCols"] = 1;
        params["iSortCol_0"] = index;
        params["bSortable_" + index] = "true";
        params["sSortDir_0"] = this.pagination.sortDesc[0] ? "desc" : "asc";
      }
      params["parentCustomerId"] = parseInt(this.$props.customerId);

      this.axios
        .get(ENDPOINTS.DATATABLES.CUSTOMERS.CUSTOMERCONTACTS, {
          params: params,
        })
        .then((res) => {
          this.total = parseInt(res.data.iTotalDisplayRecords);
          this.dataToShow = res.data.aaData;
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            color: "error",
            message: err.message,
          });

          this.dataToShow = [];
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getDataFromApi();
  },
};
</script>

<style scoped>
</style>
