<template>
  <div>
    <v-dialog v-model="dialogCreate" persistent scrollable max-width="700px">
      <v-card>
        <v-card-title
          :class="[this.$vuetify.theme.dark ? 'dark-bg' : '', 'card-header']"
        >
          {{ $t('customers.lang_create_customer_contact') }}
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form v-model="valid" ref="form" lazy-validation style="width: 100%">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="company"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('generic.lang_company')"
                    @focus="showTouchKeyboard"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6">
                  <v-select
                    v-model="salutation"
                    :items="salutations"
                    :label="$t('customers.lang_cust_anrede')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="preName"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('customers.lang_cust_prename')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="lastName"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('customers.lang_cust_name')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="streetName"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('generic.lang_street')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="streetNumber"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('customers.lang_cust_streetNo')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="zip"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('customers.lang_cust_zip')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="city"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('settings.lang_city')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required]"
                    autocomplete="off"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="country"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('generic.lang_country')"
                    @focus="showTouchKeyboard"
                    autocomplete="off"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="email"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('generic.lang_emailAdresse')"
                    @focus="showTouchKeyboard"
                    :rules="[rules.required, rules.email]"
                    autocomplete="off"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="phoneNumber"
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    :label="$t('customers.lang_cust_tel_business')"
                    @focus="showTouchKeyboard"
                    autocomplete="off"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    v-model="contactType"
                    :items="contactTypes"
                    :label="$t('customers.lang_contact_type')"
                    item-text="name"
                    item-value="id"
                    :rules="[rules.required]"
                    outlined
                    dense
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions class="card-footer pt-2 pb-2">
          <v-btn @click="closeDialogCreate" color="error" text>{{
            this.$t("generic.lang_cancel")
          }}</v-btn>

          <v-spacer />

          <v-btn
            @click="create()"
            :disabled="loading"
            :loading="loading"
            color="primary"
            elevation="0"
          >
            {{ $t("generic.lang_add") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard
        :accept="hideTouchKeyboard"
        :cancel="hideTouchKeyboard"
        :defaultKeySet="touchKeyboard.keySet"
        :input="touchKeyboard.input"
        :layout="touchKeyboard.layout"
        :options="touchKeyboard.options"
        class="internalWidthExpanded"
        id="onScreenKeyboard"
        v-if="touchKeyboard.visible"
      />
    </div>
  </div>
</template>

<script>
import mixin from "../../../mixins/KeyboardMixIns";
import { Events } from "../../../plugins/events";
import { ENDPOINTS } from "../../../config";
import swatches from "vue-swatches";
import DatePicker from "../../common/datepicker";

export default {
  name: "CreateCustomerContactComponent",
  components: {
    swatches,
    DatePicker,
  },
  mixins: [mixin],
  props: {
    dialogCreate: Boolean,
  },
  data() {
    return {
      valid: false,
      loading: false,
      customerID: null,
      company: "",
      salutation: "",
      preName: "",
      lastName: "",
      streetName: "",
      streetNumber: "",
      zip: "",
      city: "",
      country: "",
      email: "",
      phoneNumber: "",
      contactType: "",
      salutations: ["Mr", "Miss"],
      contactTypes: [
        { name: this.$t("generic.lang_contact"), id: 1 },
        { name: this.$t("accounting.lang_receipt_adress"), id: 2 },
        { name: this.$t("settings.lang_deliveryAddress"), id: 3 },
      ],
      //contactTypes: ["Contact", "Billing Address", "Delivery Address"],
      rules: {
        required: (value) => !!value || this.$t('generic.lang_requiredField'),
        email: (value) => /.+@.+/.test(value) || this.$t("generic.lang_emailMustBeValid"),
      },
    };
  },

  methods: {
    closeDialogCreate() {
      this.$emit("closeDialogCreate");
    },
    getData: function () {
      this.loading = true;
      this.salutation = "";

      this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
          customerID: this.customerID,
        })
        .then((res) => {
          if (res.status === 200) {
            this.salutations = [];
            res.data.salutations.forEach((row) => {
              this.salutations.push({ name: row[1], id: parseInt(row[0]) });
            });
          }
        })
        .catch(() => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      if (!this.$refs.form.validate()) {
        return;
      }
      let self = this;
      this.loading = true;

      this.axios
        .post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {
          ascWithID: parseInt(self.customerID),
          company: self.company,
          salutation: self.salutation,
          first_name: self.preName,
          last_name: self.lastName,
          street: self.streetName,
          street_no: self.streetNumber,
          zip: self.zip,
          city: self.city,
          country: self.country,
          email: self.email,
          tel_business: self.phoneNumber,
          ContactType: self.contactType,
        })
        .then((res) => {
          if (res.data.success === true) {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_actionSuccessful"),
              color: "success",
            });
            this.$refs.form.reset();
            this.$emit("closeDialogCreate");
          } else {
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + " ",
              color: "error",
            });
          }
        })
        .catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.customerID = this.$route.params.id;
  },
};
</script>

<style scoped>
</style>
