<template>
  <v-card width="100%" :height="height">
    <l-map ref="myMap" :style="`height: ${height}px;width: 100%;`" :zoom="zoom" :center="center" @click="addMarker($event)" readonly >
      <l-tile-layer :url="url" :attribution="attribution">
      </l-tile-layer>
      <l-marker v-if="position && position.length>0" :lat-lng="position" :icon="markerIcon">
      </l-marker>

    </l-map>
  </v-card>
</template>

<script>
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LIcon } from 'vue2-leaflet';

export default {
  name: "MapLocationPicker",
  components:{
    LMap,
    LTileLayer,
    LMarker,
    LIcon
  },
  props:{
    value:{
      default:null,
    },
    height:{
      Type:Number,
      default:500,
    },
    zoom:{
      type:Number,
      default:14,
    },
    readonly:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      z: 10,
      position:[],
      center: [54.3197273165176, 10.132141113281252],
      rotation: 0,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      markerIcon:L.icon({
        iconUrl: require('@/assets/images/marker.svg'),
        iconSize: [32, 37],
        iconAnchor: [16, 37]
      }),
      iconSize:100,
      staticAnchor:[16, 37],
    }
  },
  watch:{
    position(val){
      this.$emit('input',val);
      this.$emit('change',val);
    }
  },
  computed:{
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    }
  },
  methods:{
    addMarker(event){
      if(!this.readonly){
        this.position=Object.values(event.latlng)
      }
      //console.log(this.position)
    }
  },
  mounted() {
    setTimeout(function() { window.dispatchEvent(new Event('resize')) }, 250);
  }
}
</script>

<style>
.someExtraClass {
  background-color: aqua;
  padding: 10px;
  border: 1px solid #333;
  border-radius: 0 20px 20px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  width: auto !important;
  height: auto !important;
  margin: 0 !important;
}
</style>
