<template>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card class="mb-3 mt-3" elevation="0">

          <v-container v-if="!lunchbeeActivated">
            <p>
              {{ $t('customers.lang_toUseLunchbeeEachUserNeedsAccess') }}
            </p>
            <v-btn :disabled="customerPreName.length < 1 || customerLastName.length < 1 || customerId < 1 || loading"
                   @click="createLunchbee"
                   color="success">
                   {{ $t('generic.lang_generateLunchbeeAccess') }}
            </v-btn>
          </v-container>
          <v-container v-if="lunchbeeActivated">

            <h5 v-if="lastLogin.length > 4 ">{{ $t('customers.lang_lastLoginViaLunchbee') }}: {{ lastLogin }}</h5>
            <h5>{{ $t('generic.lang_lunchbeeUsers') }}: {{ lunchbeeUserId }}</h5>

            <p>
              {{ $t('customers.lang_resetLunchbeePasswordNoteText') }}
            </p>
            <v-btn :disabled="customerPreName.length < 1 || customerLastName.length < 1 || customerId < 1 || loading"
                   @click="resetLunchBeePassword"
                   color="warning">
                   {{ $t('generic.lang_resetLunchbeePassword') }}
            </v-btn>
            <br><br>
          </v-container>
          <v-divider></v-divider>
          <v-form>
            <v-container>
              <H5 class="text-muted pt-2 pb-2">{{ $t('generic.lang_classesAndGroups') }}</H5>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                      :label="$t('generic.lang_SelectClass')"
                      v-model="modelClass"
                      :items="classes"
                      item-text="name"
                      item-value="uuid"
                      @input="getGroups"
                      return-object
                      outlined
                      dense

                  >
                    <template v-slot:item="{ index, item }">
                      <v-row>
                        <v-col>
                          <v-chip
                              :color=item.color
                              dark
                              label
                          >
                          </v-chip>
                        </v-col>
                        <v-col>{{ item.name }}</v-col>

                      </v-row>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      :label="$t('generic.lang_selectGroup')"
                      v-model="modelGroup"
                      :items="groups"
                      :disabled="modelGroupSelectDisabled"
                      :item-text="'name'"
                      :item-value="'uuid'"
                      return-object
                      outlined
                      dense

                  >
                    <template v-slot:item="{ index, item }">
                      {{ item.name }}
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-col align="right">
                <v-btn :loading="loading" color="primary" elevation="0" @click="updateClassGroupOfCus">
                  {{ $t('generic.lang_save') }}
                </v-btn>
              </v-col>
            </v-container>
          </v-form>

        </v-card>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"/>
        </div>
      </v-card-text>


    </v-card>
  </v-container>
</template>

<script>
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns"

export default {
  name: "LunchBeeComponent",

  components: {Datatable},

  props: {
    customerId: {
      required: true,
    },
    customerPreName: {
      required: true,
    },
    customerLastName: {
      required: true,
    }
  },

  mixins: [mixin],

  data() {
    return {
      lunchbeeUserId: 0,
      loading: false,
      lastLogin: 0,
      lunchbeeActivated: false,
      name: '',
      phoneNumber: '',
      email: '',
      editing: null,

      checkbox: null,
      classes: [],
      groups: [],
      nonce: 1,
      menu: false,
      modelClass: null,
      modelGroup: null,
    }
  },

  computed: {
    modelGroupSelectDisabled() {
      if (this.modelClass === null)
        return true;

      return this.modelClass.name == null;
    }
  },

  methods: {
    createLunchbee() {
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEEUSER, {
        ascCustomerId: this.customerId
      }).then((res) => {

        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
          this.getDataFromApi();
        } else if (res.data.status === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      }).finally(() => {
        this.loading = false;
      })
    },
    resetLunchBeePassword() {
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEEUSERRESET, {
        ascCustomerId: this.customerId
      }).then((res) => {


        if (res.data.status === "SUCCESS") {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_ActionSuccessful'),
            color: "success",
          });
          this.getDataFromApi();
        } else if (res.data.status === "FAILED") {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      }).finally(() => {
        this.loading = false;
      });
    },
    getDataFromApi() {
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.LUNCHBEESTATS, {
        customerId: this.customerId
      }).then((res) => {
        this.lunchbeeActivated = res.data.success;
        this.lunchbeeUserId = res.data.userId;
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getClasses() {
      this.loading = true;
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.CLASSES.GET).then((res) => {
        this.classes = res.data.classes;
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getGroups() {
      this.loading = true;
      this.groups = [];
      this.axios.post(ENDPOINTS.KANTINE.SETTINGS.GROUPS.GET, {
        classUUID: this.modelClass.uuid
      }).then((res) => {
        this.groups = res.data.groups;
        this.loading = false;


      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getCustomersGroupsAndClasses() {
      this.loading = true;
      this.axios.post(
          ENDPOINTS.KANTINE.SETTINGS.CUSTOMER_CLASS_GROUP.GET, {
            customerId: this.$route.params.id
          }
      ).then((res) => {
        this.modelClass = res.data.class;
        this.modelGroup = res.data.group;
        this.loading = false;
        this.getGroups();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        }).finally(() => {
          this.loading = false;
        });

      });
    },
    updateClassGroupOfCus() {
      this.loading = true;
      this.axios.post(
          ENDPOINTS.KANTINE.SETTINGS.CUSTOMER_CLASS_GROUP.UPDATE, {
            classUUID: this.modelClass.uuid,
            classesGroupUUID: this.modelGroup.uuid,
            customerId: this.$route.params.id
          }
      ).then((res) => {
        this.loading = false;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    }
  },
  mounted() {
    this.getClasses();
    this.getCustomersGroupsAndClasses();
    this.getDataFromApi();
  }
}
</script>

<style scoped>

</style>
