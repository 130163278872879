<template>
  <div>
    <page-title :heading="$t('customers.lang_nav_editcust') " :icon=icon
                :subheading="this.customerName || $t('customers.lang_nav_editcust')" show-previous-button></page-title>
    <div class="app-main__inner">
      <EditCustomer v-model="customerName"></EditCustomer>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../Layout/Components/PageTitle.vue";
import EditCustomer from "../../components/customers/EditCustomer";

export default {
  components: {
    PageTitle,
    EditCustomer
  },
  props: ["id"],
  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
    customerName: null,
  })
}
</script>