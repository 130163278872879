<template>
  <div>
  <v-container class="ma-0 pa-0" fluid>
    <v-card elevation="0" outlined>
      <v-card-text class="ma-0 pa-0">
        <v-card elevation="0">
          <v-card-title class="pa-0 ma-0">

          </v-card-title>
        </v-card>
<v-container>
  <v-row no-gutters>
    <v-col xs="6" sm="6" md="6" class="pr-3">
      <v-btn
          @click="reCreateAccountNo"
          color="warning">
          {{ $t('customers.lang_cardLost_generateNewAccountNumber') }}
        </v-btn>

    </v-col>
    <v-col xs="6" sm="6" md="6" class="pr-3">
      <v-btn
            @click="showPdfDialog=true"
             color="success">
             {{ $t('customers.lang_issueCard') }}
      </v-btn>

    </v-col>
  </v-row><br>
  <v-row no-gutters>
  <v-col xs="12" sm="12" md="6" class="pr-3">
    <p>{{ $t('customers.lang_chargeAccountHintText') }}</p>
    <v-row>
      <v-col
          cols="12"
          sm="6">
        <v-text-field
                      :label="$t('generic.lang_total')"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      outlined
                      v-model="chargeAccount"
                      sm="6"
                      type="number"
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC"
        >
        </v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="6">
        <v-btn :disabled="chargeAccount < 0.01 || customerPreName.length < 1 || customerLastName.length < 1 || customerId < 1"
               @click="chargeAccountFunc"
               color="success">
               {{ $t('emailTmp.lang_GuthabenAufladen') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <br>
    <p>{{ $t('customers.lang_dechargeAccountHintText') }}</p>
    <v-row>
      <v-col
          cols="12"
          sm="6">
        <v-text-field
                      :label="$t('generic.lang_total')"
                      @focus="showTouchKeyboard"
                      autocomplete="off"
                      outlined
                      v-model="dechargeAccount"
                      sm="6"
                      type="number"
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC"
        >
        </v-text-field>
      </v-col>
      <v-col
          cols="12"
          sm="6">
        <v-btn :disabled="dechargeAccount < 0.01 || customerPreName.length < 1 || customerLastName.length < 1 || customerId < 1"
               @click="dechargeAccountFunc"
               color="success">
               {{ $t('customers.lang_withdrawCredit') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-col>
  <v-col xs="12" sm="12" md="6">
<h6 style="float:left;">{{ $t('erp.lang_voucher_balance') }}: {{currentBalance | currency}} </h6><h6 style="float:right;"> {{ $t('customers.lang_whichReserved') }}: {{currentBalanceReserved | currency}}</h6>
    <br style="clear:both;"><br>
    <h5>{{ $t('customers.lang_accountMovements') }}</h5>
    <Datatable id="accountDataTable" ref="accountDataTable"
               :api-endpoint="ENDPOINTS.DATATABLES.CUSTOMERS.ACCOUNT+'?customerID='+customerId"
               :datatable-headers="datatableHeaders"
               :excel-columns="excelColumns"
               excel-file-name="Accounts"
    >
      <template v-slot:item.credit="{item}">
        {{ item.credit | currency }}
      </template>
      <template v-slot:item.reserved="{item}">
        {{ item.reserved | currency }}
      </template>
      <template v-slot:item.creditChange="{item}">
        {{ item.creditChange | currency }}
      </template>
      <template v-slot:item.reservedChange="{item}">
        {{ item.reservedChange | currency }}
      </template>
    </Datatable>
  </v-col>
  </v-row>
</v-container>

        <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
          <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboard"
                              :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                              :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                              :options="touchKeyboard.options" class="internalWidthExpanded"/>
        </div>
      </v-card-text>


    </v-card>
  </v-container>

  <PDFPreviewDialog  :drawer="showPdfDialog" @close="showPdfDialog=false" :api="'get/customer/accountCard/'" :data="customerId"/>
</div>
</template>

<script>
import FileSaver from 'file-saver';
import Datatable from "../../datatable/Datatable";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import mixin from "../../../mixins/KeyboardMixIns"
import PDFPreviewDialog from "@/components/common/PDFPreviewDialog";

import moment from 'moment'

export default {
  name: "AccountComponent",
  components: { Datatable,
    PDFPreviewDialog},
  props: {
    customerId: {
      required: true,
    },
    customerPreName: {
      required: true,
    },
    customerLastName: {
      required: true,
    }
  },
  mixins: [mixin],
  data() {
    return {
      ENDPOINTS,
      search: "",
      showPdfDialog:false,
      currentBalance: 0.00,
      currentBalanceReserved: 0.00,
      chargeAccount:0.00,
      dechargeAccount:0.00,
      loading: false,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'Zeit',
          align: 'left',
          value: 'time',
          hide: false,
          sort: "asc"
        },
        {text: this.$t('generic.lang_type'), value: "type",sortable: false},
        {text: this.$t('erp.lang_voucher_balance'), value: "credit",sortable: false},
        {text: this.$t('generic.lang_reserved'), value: "reserved",sortable: false},
        {text: this.$t('generic.lang_creditChange'), value: "creditChange",sortable: false},
        {text: this.$t('generic.lang_reservedChange'), value: "reservedChange",sortable: false},

      ],
      excelColumns: [
        {
          label: this.$t('generic.lang_time'),
          field: 'time',
        },
        {
          label: this.$t('generic.lang_type'),
          field: 'type',
        },
        {
          label: this.$t('erp.lang_voucher_balance'),
          field: "credit",
        },
        {
          label: this.$t('generic.lang_reserved'),
          field: "reserved",
        },
        {
          label: this.$t('generic.lang_creditChange'),
          field: "creditChange",
        },
        {
          label: this.$t('generic.lang_reservedChange'),
          field: "reservedChange",
        }
      ],
    }
  },
  computed: {

  },

  watch: {

  },
  methods: {

    chargeAccountFunc(){
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CHARGEACCOUNT, {
        customerId: this.customerId,
        charge: this.chargeAccount
      }).then((res) => {
        this.lunchbeeActivated = res.data.success;
        this.loading = false;
        this.chargeAccount = 0.00;

        this.$refs.accountDataTable.getDataFromApi();
        this.$refs.accountDataTable.resetSelectedRows();
        this.getDataFromApi();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    reCreateAccountNo(){
      this.$swal({
        title: this.$t('customers.lang_cardLost_newAccountNumber'),
        text: this.$t('customers.lang_doYouReallyWantToCreateNewAccountNumber'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.RECREATEACCOUNTUUID, {
            ascCustomerId: this.customerId
          }).then((res) => {
            if (res.data.success === true) {
              Events.$emit("showSnackbar", {
                message: this.$t('customers.lang_newAccountNumberGenerated'),
                color: "success"
              });
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },
    dechargeAccountFunc(){
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.DISCHARGEACCOUNT, {
        customerId: this.customerId,
        charge: this.dechargeAccount
      }).then((res) => {
        this.lunchbeeActivated = res.data.success;
        this.loading = false;
        this.dechargeAccount = 0.00;

        this.$refs.accountDataTable.getDataFromApi();
        this.$refs.accountDataTable.resetSelectedRows();
        this.getDataFromApi();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    },
    getDataFromApi() {
      this.loading = true;
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.ACCOUNTSTATS, {
        customerId: this.customerId
      }).then((res) => {
        this.lunchbeeActivated = res.data.success;
        this.loading = false;
        this.currentBalance = res.data.credit;
        this.currentBalanceReserved = res.data.reserved;
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });

      });
    }
  },
  mounted() {
    this.getDataFromApi();
  }
}
</script>

<style scoped>
.dataTables_filter {
  display: none;
}
</style>